.titlePage {
  width: 100%;
  padding: 0 1em; }
  .titlePage h1 {
    color: #97BF0D; }

.prebandeau > .column {
  padding: 0; }
  @media only print, only screen and (min-width: 48.063em) {
    .prebandeau > .column:before {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 0;
      bottom: 0;
      right: 0;
      background-color: #ecebde; } }

.richContent {
  padding-bottom: 2em; }

.actualites {
  background-color: #ecebde; }
  .actualites > h2 {
    margin: 1em 0.5em 1em 1em;
    padding-left: 0.5em;
    border-left: 3px solid #093C22;
    font-size: 1.563em;
    font-weight: 600; }
  .actualites .css3slider {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden; }
  .actualites .css3slider-item {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    transition-delay: 0s; }
    .actualites .css3slider-item > img {
      display: none; }
    .trans-fade .actualites .css3slider-item.active {
      opacity: 1; }
    .trans-accordeon .actualites .css3slider-item {
      opacity: 1; }
      .trans-accordeon .actualites .css3slider-item:first-child {
        transform: translateX(0%); }
    .trans-ltr .actualites .css3slider-item,
    .trans-rtl .actualites .css3slider-item,
    .trans-btt .actualites .css3slider-item,
    .trans-ttb .actualites .css3slider-item {
      opacity: 1; }
  .actualites .boxArticles_slider {
    position: relative;
    width: 100%;
    height: 20em;
    overflow: hidden; }
    .actualites .boxArticles_slider .items {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      bottom: 3em;
      z-index: 1; }
      .no-touch .actualites .boxArticles_slider .items {
        bottom: 2em; }
    .actualites .boxArticles_slider .listArticle .listArticle-article {
      overflow: hidden;
      cursor: pointer; }
    .actualites .boxArticles_slider .article-illust {
      float: left;
      padding-left: 2em;
      margin-top: 2.2em;
      width: 33%; }
    .actualites .boxArticles_slider .article-content {
      float: right;
      width: 66%;
      padding-right: 1em; }
      .actualites .boxArticles_slider .article-content .richContent {
        margin-top: 1em; }
      .actualites .boxArticles_slider .article-content .article-title {
        font-weight: normal;
        margin-bottom: 0;
        font-size: 2.6vw; }
      .actualites .boxArticles_slider .article-content .article-date {
        font-size: 0.8em; }
      .actualites .boxArticles_slider .article-content .article-overview {
        font-size: 1.7vw; }
      .actualites .boxArticles_slider .article-content .article-lien {
        white-space: nowrap;
        text-decoration: none;
        color: #093C22; }
        .actualites .boxArticles_slider .article-content .article-lien:hover {
          color: #849e91; }
      @media only print, only screen and (min-width: 60.063em) {
        .actualites .boxArticles_slider .article-content .article-title {
          font-size: 1.563em; }
        .actualites .boxArticles_slider .article-content .article-overview {
          font-size: 1rem; } }
  .actualites .boxArticles_navigator {
    position: absolute;
    bottom: 0;
    z-index: 3;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    display: none; }
    @media only print, only screen and (min-width: 48.063em) {
      .actualites .boxArticles_navigator {
        display: table; } }
    .actualites .boxArticles_navigator .controleBar-item {
      display: inline-block;
      position: relative;
      text-indent: -9999px;
      position: relative;
      font-size: 2em;
      width: 1em;
      height: 1em;
      margin: 0 0.125em;
      background-color: #fff;
      border: solid 0.125em #97BF0D;
      border-radius: 0.5em; }
      .actualites .boxArticles_navigator .controleBar-item:hover {
        background-color: #97BF0D;
        border-color: #97BF0D; }
      .actualites .boxArticles_navigator .controleBar-item:before {
        content: '';
        position: absolute;
        display: block;
        background-color: #97BF0D;
        width: 1em;
        height: 1em;
        margin: -0.5em;
        border-radius: 0.5em;
        left: 50%;
        top: 50%;
        transform: scale(0);
        transition: transform 0.25s linear; }
      .actualites .boxArticles_navigator .controleBar-item.active:before {
        transform: scale(1); }
      .no-touch .actualites .boxArticles_navigator .controleBar-item {
        font-size: 1em; }
